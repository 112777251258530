import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { ControlLabel, FormControl } from 'react-bootstrap';
import Select from 'react-select';

import * as workguideActions from '../../../../actions/WorkguideActions';

import EventPollingConfiguration from './EventPolling';
import { MappingList } from './ServiceCall/index';
import Explanation from './ServiceCall/Explanation';
import { methods } from './ServiceCall/constants';

class ServiceCallConfig extends React.Component {
  constructor(props) {
    super(props);
    const { workguideActions } = props;

    this.state = {
      service: undefined,
      add: false,
      url: ''
    };

    workguideActions.workguideServicesRequest();
    if (_.has(props, 'params.service')) workguideActions.workguideServiceDefinitionRequest(_.get(props, 'params.service'));

    this.onServiceChange = this.onServiceChange.bind(this);
    this.onMethodChange = this.onMethodChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onMappingChange = this.onMappingChange.bind(this);
  }

  onMappingChange(mapping) {
    const { onChange } = this.props;

    onChange('mapping', mapping);
  }

  onServiceChange(selected) {
    const { workguideActions, onChangeMany } = this.props;
    const { value } = selected;
    workguideActions.workguideServiceDefinitionRequest(value);

    onChangeMany({
      service: value,
      url: value
    });
  }

  onUrlChange(ev) {
    const { onChange } = this.props;
    const { value } = ev.target;

    onChange('url', value);
  }

  onMethodChange(selected) {
    const { onChange } = this.props;
    const { value } = selected;
    onChange('method', value);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      services,
      params,
      serviceDefinition,
      edit,
      onChange
    } = this.props;
    const { service, method, mapping = [], url } = params;

    return (
      <div>
        <Explanation />
        <ControlLabel>Service</ControlLabel>
        <Select
          value={service}
          options={services.map(s => ({ value: s, label: s }))}
          onChange={this.onServiceChange}
          disabled={!edit}
        />

        {(service) && (
          <React.Fragment>
            <ControlLabel style={{ paddingTop: '5px' }}>URL Parameter (Kann JMES Path instruktion enthalten)</ControlLabel>
            <FormControl
              type="textarea"
              value={url}
              onChange={this.onUrlChange}
              disabled={!edit}
            />

            <ControlLabel style={{ paddingTop: '5px' }}>Method</ControlLabel>
            <Select
              value={method}
              options={methods}
              onChange={this.onMethodChange}
              disabled={!edit}
            />

            <EventPollingConfiguration
              edit={edit}
              params={params}
              onChange={onChange}
            />

            <MappingList
              edit={edit}
              mapping={mapping}
              serviceDefinition={serviceDefinition}
              onChange={this.onMappingChange}
              method={_.get(params, 'method')}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

ServiceCallConfig.propTypes = {
  edit: PropTypes.bool,
  workguide: PropTypes.object,
  services: PropTypes.array,
  params: PropTypes.object,
  onChange: PropTypes.func,
  onChangeMany: PropTypes.func
};

ServiceCallConfig.defaultProps = {
  edit: false,
  workguide: {},
  services: [],
  params: {},
  onChange: _.noop,
  onChangeMany: _.noop
};

function mapStateToProps(state) {
  return {
    workguide: state.workguide.workguide,
    services: state.workguide.services,
    serviceDefinition: state.workguide.serviceDefinition
  };
}

function mapDispatchToProps(dispatch) {
  return { workguideActions: bindActionCreators(workguideActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCallConfig);
