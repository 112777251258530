import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isUndefined, isNull, noop } from 'lodash';
import { createStructuredSelector } from 'reselect';
import Select from 'react-virtualized-select';
import { FormGroup, ControlLabel, InputGroup } from 'react-bootstrap';

import {
  CONSULTANTS_FETCH_ACTIONS,
} from '../../../actions/ConsultantsActions';
import {
  getConsultants,
  getConsultantsLoading,
} from '../../../selectors/Consultants/consultantsSelectors';


function getConsultantOptions(consultants) {
  if (!consultants) {
    return [];
  }
  return consultants.filter((c) => get(c, 'isActive', false)).map((consultant) => ({
    value: get(consultant, 'id'),
    label: `${get(consultant, 'firstName')} ${get(consultant, 'lastName')} (${get(consultant, 'username')})`
  }));
}

class Assignee extends React.Component {
  constructor(props) {
    super(props);

    if (!props.consultants) {
      props.fetchConsultants();
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(selected) {
    const { editValueField } = this.props;
    const value = get(selected, 'value');

    editValueField('', 'workguidePropery', { key: 'defaultAssignee', value });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { consultants, workguide } = this.props;

    const value = get(workguide, 'defaultAssignee');
    const isValid = ['bpf', 'bpfCustom', 'emailWithActivity', 'activity', 'approval'].includes(get(workguide, 'type'))
      ? !isUndefined(value) && !isNull((value))
      : true;

    return (
      <div className="workguide-detail-general-assignee">
        <FormGroup>
          <ControlLabel>
            Bearbeiter (assignee) nach Ausführung
          </ControlLabel>

          <InputGroup>
            <Select
              id="defaultAssignee"
              value={value}
              onChange={this.onChange}
              options={getConsultantOptions(consultants)}
            />

            <InputGroup.Addon>
              <span className="mdi mdi-check-outline" style={{ color: isValid ? 'green' : 'red' }} />
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>
      </div>
    );
  }
}

Assignee.propTypes = {
  fetchConsultants: PropTypes.func.isRequired,
  consultants: PropTypes.array,
  loading: PropTypes.bool,
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

Assignee.defaultProps = {
  editValueField: noop
};

const mapStateToProps = createStructuredSelector({
  consultants: getConsultants,
  loading: getConsultantsLoading,
});

const mapDispatchToProps = {
  fetchConsultants: () => CONSULTANTS_FETCH_ACTIONS.request(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Assignee);
