import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { TextInput, ValidationResult } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const ActionConfigurationDeleteCustomerSuspension = React.memo(function ActionConfigurationDeleteCustomerSuspension({
  action,
  onJmesParamChange,
  validations
}) {
  return (
    <div className="workguide-action-configuration-delete-customer-suspension">
      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.CustomerSuspension.JmesCustomerId" />
        </ControlLabel>

        <TextInput
          id="customerId"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.customerId')}
        />

        <ValidationResult
          show
          validations={get(validations, 'customerId')}
        />
      </FormGroup>
    </div>
  );
});

ActionConfigurationDeleteCustomerSuspension.propTypes = {
  action: PropTypes.object.isRequired,
  onJmesParamChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationDeleteCustomerSuspension.defaultProps = {
  onJmesParamChange: noop,
  validations: {}
};

export default ActionConfigurationDeleteCustomerSuspension;
