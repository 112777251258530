import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';
import { removeHost } from '@evoja-web/redux-saga-utils';

import endpointsAction from '../../actions/Graviton/Endpoints';

const {
  ENDPOINTS_PENDING,
  ENDPOINTS_FULFILLED,
  ENDPOINTS_REJECTED
} = endpointsAction;

export default function* getEndpoints(request) {
  const { dataKey } = request;

  yield put({ type: ENDPOINTS_PENDING, dataKey });

  try {
    const { data } = yield call(axios, {
      url: '/',
      method: 'get',
      baseUrl: dataKey
    });

    const payload = get(data, 'services', []).map((e) => ({
      name: removeHost(e.$ref),
      uri: e.$ref,
      schema: e.profile
    }));

    yield put({ type: ENDPOINTS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: ENDPOINTS_REJECTED, dataKey, error });

    return error;
  }
}
