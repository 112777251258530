export const methods = [{
  value: 'post',
  label: 'POST'
}, {
  value: 'put',
  label: 'PUT'
}, {
  value: 'patch',
  label: 'PATCH'
}];
