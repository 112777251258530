import React from 'react';

const Explanation = React.memo(() => {
  return (
    <div>
      <strong>Erklärung</strong><br />
      <p>
        Nach dem Ausführen eines Workguides können beliebig viele Services aufgerufen werden.<br />
        Es gibt verschiedene Arten von Calls (im Moment Post, Put und Patch).<br />
        - Mit Post kann ein neuer Eintrag erstellt werden. Hier muss darauf geachtet werden, dass die nötigen required Felder abgefüllt sind.<br />
        - Mit Put kann ein Eintrag aktualisiert werden. Auch hier müssen alle Felder befüllt sein.<br />
        - Mit Patch können einzelne Werte aktualisiert werden. Hierzu wird Json Patch verwendet (http://jsonpatch.com/). Hier können einzelne Felder ausgewählt werden.<br />
      </p>

      <p>
        <strong>Daten</strong><br />
        Folgende Daten stehen zur Verfügung:<br />
        - consultant: Aktuelle User<br />
        - customer: Aktueller Kunde<br />
        - formData: Formulardaten aus dem Workguide<br />
        - state: Der aktuelle State zum Zeitpunkt des absendens (Um die korrekten Werte zu finden muss man in der Konsole des Browsers halt den State durchgehen)<br />
        - workguide: Gewählter Workguide<br />
      </p>
    </div>
  );
});

export default Explanation;
