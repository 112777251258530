import { get } from 'lodash';

import ConditionValidaitonDefinition from './ConditionValidaitonDefinition';

export default {
  validations: {
    title: {
      type: 'object',
      required: true,
      validations: {
        de: {
          type: 'string',
          required: true,
          validations: {}
        },
        fr: {
          type: 'string',
          required: true,
          validations: {}
        }
      }
    },
    description: {
      type: 'object',
      required: false,
      validations: {
        de: {
          type: 'string',
          required: false,
          validations: {}
        },
        fr: {
          type: 'string',
          required: false,
          validations: {}
        }
      }
    },
    isRejectable: {
      type: 'boolean',
      required: false,
      validations: {}
    },
    assigneeId: {
      type: 'object',
      required: false,
      validations: {
        default: {
          type: 'string',
          required: false,
          validations: {}
        },
        required: {
          type: 'boolean',
          required: false,
          validations: {}
        },
        disabled: {
          type: 'boolean',
          required: false,
          validations: {}
        }
      }
    },
    completionDate: {
      type: 'object',
      validations: {
        default: {
          type: 'string',
          required: false,
          validations: {}
        },
        required: {
          type: 'boolean',
          required: false,
          validations: {}
        },
        disabled: {
          type: 'boolean',
          required: false,
          validations: {}
        },
        hidden: {
          type: 'boolean',
          required: false,
          validations: {}
        }
      }
    },
    comment: {
      type: 'object',
      required: false,
      validations: {
        default: {
          type: 'object',
          required: false,
          validations: {
            de: {
              type: 'string',
              required: false,
              validations: {}
            },
            fr: {
              type: 'string',
              required: false,
              validations: {}
            }
          }
        },
        required: {
          type: 'boolean',
          required: false,
          validations: {}
        },
        disabled: {
          type: 'boolean',
          required: false,
          validations: {}
        },
        hidden: {
          type: 'boolean',
          required: false,
          validations: {}
        }
      }
    },
    actions: {
      type: 'array',
      required: false,
      validations: {
        id: {
          type: 'string',
          required: true,
          validations: {}
        },
        type: {
          type: 'string',
          required: true,
          validations: {}
        },
        order: {
          type: 'number',
          required: true,
          validations: {}
        },
        stopExecutionOnError: {
          type: 'boolean',
          required: false,
          validations: {}
        },
        condition: ConditionValidaitonDefinition,
        url: {
          type: 'string',
          required: ({ parent }) => ['ServiceCall', 'ExternalServiceCall'].includes(get(parent, 'type')),
          validations: {}
        },
        jmesParams: {
          type: 'object',
          required: false,
          validations: {}
        }
      }
    }
  }
};
