import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ControlLabel, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';

import Explanation from './ServiceCall/Explanation';
import { methods } from './ServiceCall/constants';
import MappingList from './ExternalServiceCall/MappingList';
import EventPollingConfiguration from './EventPolling';

class ExternalServiceCall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false,
      url: ''
    };

    this.onMappingChange = this.onMappingChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onMethodChange = this.onMethodChange.bind(this);
  }

  onMappingChange(mapping) {
    const { onChange } = this.props;

    onChange('mapping', mapping);
  }

  onUrlChange(ev) {
    const { onChange } = this.props;
    const { value } = ev.target;

    onChange('url', value);
  }

  onMethodChange(selected) {
    const { onChange } = this.props;
    const { value } = selected;
    onChange('method', value);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { params, edit, onChange } = this.props;
    const { method, mapping = [], url } = params;

    return (
      <div>
        <Explanation />

        <ControlLabel style={{ paddingTop: '5px' }}>URL Parameter (Kann JMES Path instruktion enthalten)</ControlLabel>
        <FormControl
          type="textarea"
          value={url}
          onChange={this.onUrlChange}
          disabled={!edit}
        />

        <ControlLabel style={{ paddingTop: '5px' }}>Method</ControlLabel>
        <Select
          value={method}
          options={methods}
          onChange={this.onMethodChange}
          disabled={!edit}
        />

        <EventPollingConfiguration
          edit={edit}
          params={params}
          onChange={onChange}
        />

        <MappingList
          edit={edit}
          mapping={mapping}
          onChange={this.onMappingChange}
          method={_.get(params, 'method')}
        />
      </div>
    );
  }
}

ExternalServiceCall.propTypes = {
  edit: PropTypes.bool,
  workguide: PropTypes.object,
  params: PropTypes.object,
  onChange: PropTypes.func,
  onChangeMany: PropTypes.func
};

ExternalServiceCall.defaultProps = {
  edit: false,
  workguide: {},
  params: {},
  onChange: _.noop,
  onChangeMany: _.noop
};

function mapStateToProps(state) {
  return {
    workguide: state.workguide.workguide,
    services: state.workguide.services,
    serviceDefinition: state.workguide.serviceDefinition
  };
}

export default connect(mapStateToProps)(ExternalServiceCall);
