import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../../actions/Graviton/Actions';
import getEndpoints from './getEndpoints';

const {
  ENDPOINTS_REQUEST
} = actions;

export default function* gravitonRootSaga() {
  yield takeLatestByDataKey(ENDPOINTS_REQUEST, getEndpoints);
}
