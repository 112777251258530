import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListGroup, Button } from 'react-bootstrap';
import update from 'immutability-helper';

import MappingListItem from './MappingListItem';
import MappingForm from './Form';

export default class MappingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(item, index = -1) {
    const { onChange } = this.props;

    let updated = [ ...this.props.mapping ]
    if (index > -1) {
      updated = update(updated, {
        $splice: [[index, 1, item]]
      });
    } else {
      updated = update(updated, {
        $push: [item]
      });
    }

    onChange(updated);
    this.setState({ add: false });
  }

  onRemove(index = -1) {
    const { mapping, onChange } = this.props;

    if (index > -1) {
      const updated = update(mapping, {
        $splice: [[index, 1]]
      });

      onChange(updated);
    }
  }

  render() {
    const { add } = this.state;
    const { mapping, serviceDefinition, method, edit } = this.props;

    const children = mapping.map((m, index) => (
      <MappingListItem
        editable={edit}
        method={method}
        key={index}
        item={m}
        serviceDefinition={serviceDefinition}
        onRemove={() => this.onRemove(index)}
        onChange={(item) => this.onChange(item, index)}
      />
    ));

    return (
      <div>
        <h4>Mapping</h4>

        {(serviceDefinition && !add) ? (
          <Button onClick={() => this.setState({ add: true })} disabled={!edit}>
            <i className="glyphicon glyphicon-plus" style={{ fontSize: '20px' }} />
          </Button>
        ) : null}

        {(add) ? (
          <MappingForm
            serviceDefinition={serviceDefinition}
            onSubmit={this.onChange}
            onCancel={() => this.setState({ add: false })}
            method={method}
          />
        ) : null}

        <ListGroup>
          {children}
        </ListGroup>
      </div>
    );
  }
}

MappingList.propTypes = {
  mapping: PropTypes.array,
  onChange: PropTypes.func,
  serviceDefinition: PropTypes.object,
  method: PropTypes.string
};

MappingList.defaultProps = {
  mapping: [],
  onChange: _.noop,
  serviceDefinition: undefined,
  method: undefined
};
