import React from 'react';
import PropTypes from 'prop-types';
import { get, isUndefined, every, defaultsDeep, noop } from 'lodash';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';

class EventPollingConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = defaultsDeep(
      {},
      get(props, 'params.eventPolling', {}),
      {
        active: false,
        interval: 3000,
        maxTries: 20
      }
    );

    this.onActiveChange = this.onActiveChange.bind(this);
    this.onNumbeFieldChange = this.onNumbeFieldChange.bind(this);
  }

  onActiveChange(ev) {
    const { active } = this.state;

    const updated = {
      ...this.state,
      active: !active
    };

    this.setState(updated);

    this.onDataChange(updated);
  }

  onNumbeFieldChange(field, ev) {
    const value = get(ev, 'target.value', '');

    if (isUndefined(value) || get(value.trim(), 'length', 0) === 0) {
      return this.setState({ [field]: undefined });
    }

    const intValue = parseInt(value, 10);

    const updated = {
      ...this.state,
      [field]: intValue
    };

    this.setState(updated);

    this.onDataChange(updated);
  }

  onDataChange(data = {}) {
    const { onChange } = this.props;

    const isValid = every(Object.values(data), (v) => !isUndefined(v)) && get(data, 'active', false);

    return isValid
      ? onChange('eventPolling', data)
      : onChange('eventPolling', {});
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      active,
      interval,
      maxTries
    } = this.state;
    const { edit } = this.props;

    return (
      <div className="workguide-event-polling-configuraion">
        <FormGroup>
          <Row>
            <Col lg={12}>
              <strong>Event Polling</strong>
            </Col>

            <Col lg={12}>
              <Toggle
                checked={active}
                disabled={!edit}
                onChange={this.onActiveChange}
              />
            </Col>
          </Row>
        </FormGroup>

        {active && (
          <div>
            <FormGroup validationState={isUndefined(interval) ? 'error' : 'success'}>
              <Row>
                <Col lg={12}>
                  <strong>Poll Interval</strong>
                </Col>

                <Col lg={12}>
                  <FormControl
                    type="number"
                    disabled={!edit}
                    value={interval}
                    onChange={(ev) => this.onNumbeFieldChange('interval', ev)}
                  />
                  <FormControl.Feedback />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup validationState={isUndefined(maxTries) ? 'error' : 'success'}>
              <Row>
                <Col lg={12}>
                  <strong>Maxmimum Versuche</strong>
                </Col>

                <Col lg={12}>
                  <FormControl
                    type="number"
                    disabled={!edit}
                    value={maxTries}
                    onChange={(ev) => this.onNumbeFieldChange('maxTries', ev)}
                  />
                  <FormControl.Feedback />
                </Col>
              </Row>
            </FormGroup>
          </div>
        )}
      </div>
    );
  }
}

EventPollingConfiguration.propTypes = {
  edit: PropTypes.bool,
  params: PropTypes.object,
  onChange: PropTypes.func
};

EventPollingConfiguration.defaultProps = {
  eidt: false,
  params: {},
  onChange: noop
};

export default EventPollingConfiguration;
