import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { CONSULTANTS_FETCH_ACTIONS } from '../../actions/ConsultantsActions';

export function* consultantsFetchSaga() {
  try {
    yield put(CONSULTANTS_FETCH_ACTIONS.pending());

    
    const { data: consultants } = yield call(axios, { url: `${EndpointName.PERSON_CONSULTANT}/?limit(9999)` });
    
    yield put(CONSULTANTS_FETCH_ACTIONS.success({ consultants }));
  } catch (error) {
    yield put(CONSULTANTS_FETCH_ACTIONS.failure(error));
  }
}
