import './EmailSubscriptionsItem.css';

import React from 'react';
import { connect } from 'react-redux';
import { Transition } from 'react-transition-group';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { TableCell, TableRow } from '../Common/Table';
import { EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM } from './EmailSubscriptionsPropTypes';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { EmailSubscriptionsEditForm } from './EmailSubscriptionsEditForm';
import { getEmailSubscriptionsLoading } from '../../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';
import {
  getEmailSubscriptionsStateOptions,
  getEmailSubscriptionsTypeOptions,
} from '../../selectors/EmailSubscriptions/emailSubscriptionsOptionsSelector';
import { EMAIL_SUBSCRIPTIONS_REMOVE, EMAIL_SUBSCRIPTIONS_SAVE } from '../../actions/EmailSubscriptionsActions';
import { ConfirmationDialog } from '../Common/ConfirmationDialog';
import { validateEmailSubscriptionsEditForm } from '../../services/EmailSubscriptions/EmailSubscriptionsEditFormValidator';

class EmailSubscriptionsItemConnected extends React.PureComponent {
  state = {
    isModalOpen: false,
    isConfirmationDialogOpen: false,
  };

  render() {
    const { item } = this.props;

    return (
      <React.Fragment>
        <TableRow className="email-subscriptions-item">
          <TableCell>
            {item.view.name}
          </TableCell>
          <TableCell>
            {item.view.email}
          </TableCell>
          <TableCell>
            {item.view.subscriptionType}
          </TableCell>
          <TableCell>
            {item.view.subscriptionState}
          </TableCell>
          <TableCell>
            {item.view.createDate}
          </TableCell>
          <TableCell className="email-subscriptions-item__action">
            <span className="icon-025-edit" onClick={this.openModal}/>
            <span className="icon-008-bin" onClick={this.openConfirmationDialog}/>
            {this.renderModal()}
            {this.renderConfirmationDialog()}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { loading, item, subscriptionTypeOptions, subscriptionStateOptions } = this.props;
    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton>
          <Modal.Title className="email-subscriptions-item__modal-title">
            ID {item.id}{' '}
            <span className="icon-008-bin email-subscriptions-item__remove"
                  onClick={this.openConfirmationDialog}/>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Transition
            in={isModalOpen}
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={{ enter: 0, exit: 500 }}
          >
            <EmailSubscriptionsEditForm
              form={`editForm-${item.id}`}
              save={this.save}
              saving={loading}
              close={this.closeModal}
              subscriptionTypeOptions={subscriptionTypeOptions}
              subscriptionStateOptions={subscriptionStateOptions}
              initialValues={item.form}
              validate={validateEmailSubscriptionsEditForm}
            />
          </Transition>
        </ModalBody>
      </ModalDialog>
    );
  }

  renderConfirmationDialog() {
    const { item } = this.props;
    const { isConfirmationDialogOpen } = this.state;
    return (
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        confirm={this.confirmRemoving}
        cancel={this.cancelRemoving}
        title={<FormattedMessage id="emailSubscriptions_confirmationTitle"/>}
        content={<FormattedMessage id="emailSubscriptions_confirmationText" values={{ name: item.view.name }}/>}
      />
    );
  }

  save = (formData) => {
    const { item, save } = this.props;
    save(item.id, formData);
    this.closeModal();
  };
  remove = () => {
    const { item, remove } = this.props;
    remove(item.id);
    this.closeModal();
  };
  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });
  openConfirmationDialog = () => this.setState({ isConfirmationDialogOpen: true });
  cancelRemoving = () => this.setState({ isConfirmationDialogOpen: false });
  confirmRemoving = () => this.setState({ isConfirmationDialogOpen: false }, this.remove)
}

const mapStateToProps = createStructuredSelector({
  loading: getEmailSubscriptionsLoading,
  subscriptionTypeOptions: getEmailSubscriptionsTypeOptions,
  subscriptionStateOptions: getEmailSubscriptionsStateOptions,
});

const mapDispatchToProps = {
  save: (id, formData) => EMAIL_SUBSCRIPTIONS_SAVE.request(formData, id),
  remove: (id) => EMAIL_SUBSCRIPTIONS_REMOVE.request(null, id),
};

export const EmailSubscriptionsItem = connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptionsItemConnected);
EmailSubscriptionsItem.propTypes = {
  item: EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM.isRequired,
};
