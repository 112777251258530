import React from 'react';
import PropTypes from 'prop-types';
import { chain, has, get, noop, last } from 'lodash';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';
import { v4 } from 'uuid';
import cl from 'classnames';

import './Actions.css';
import Collapsible from '../../../../General/Collapsible';
import Action from './Action/Action';
import ConfigurationComponents from './Action/Configuration/Components';

class WorkflowTreeActions extends React.Component {
  constructor(props) {
    super(props);

    this.onActionAdd = this.onActionAdd.bind(this);
    this.onActionRemove = this.onActionRemove.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
  }

  /**
   * Add a new action with the given type
   *
   * @param   {String}  type  Action type
   *
   * @return  void
   */
  onActionAdd({ type }) {
    const {
      form,
      onValueChange
    } = this.props;
    const current = get(form, 'data.actions', []);
    const id = `act${last(v4().split('-'))}`;
    const action = {
      id,
      order: 0,
      type,
      jmesParams: {}
    };

    const updated = update(current, { $push: [action] });
    onValueChange('actions', updated);
  }

  /**
   * Remove the given action
   *
   * @param   {Object}  action  Action to remove
   *
   * @return  void
   */
  onActionRemove({ action }) {
    const {
      form,
      onValueChange
    } = this.props;
    const current = get(form, 'data.actions', []);
    const index = current.findIndex((a) => a.id === action.id);
    if (index > -1) {
      const updated = update(current, { $splice: [index, 1] });
      onValueChange('actions', updated);
    }
  }

  /**
   * Handle action change
   *
   * @param   {Object}  action  Updated action
   *
   * @return  void
   */
  onActionChange({ action }) {
    const {
      form,
      onValueChange
    } = this.props;

    const actions = get(form, 'data.actions', []);
    const index = actions.findIndex((a) => a.id === action.id);

    if (index > -1) {
      const updated = update(actions, { $splice: [[index, 1, action]] });
      onValueChange('actions', updated);
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      form,
      validations
    } = this.props;

    const menuItems = Object
      .keys(ConfigurationComponents)
      .map((type) => {
        return (
          <MenuItem
            key={type}
            onClick={() => this.onActionAdd({ type })}
          >
            <FormattedMessage id={`Workguide.Workflow.Form.EditNode.Action.${type}`} />
          </MenuItem>
        );
      });

    const children = chain(form)
      .get('data.actions', [])
      .sortBy(['order'])
      .map((action, index) => {
        return (
          <Action
            key={action.id}
            action={action}
            onChange={this.onActionChange}
            validations={get(validations, `actions.${index}`)}
          />
        );
      })
      .value();

    return (
      <Collapsible
        className={cl({
          'workflow-tree-form-edit-node--collapsible': true,
          'workflow-tree-form-edit-node--collapsible--invalid': has(validations, 'actions'),
          'workflow-tree-form-edit-node--actions': true
        })}
        isCollapsed
        label={<FormattedMessage id="Workguide.Workflow.Form.EditNode.Title.Actions" />}
      >
        <div className="workflow-tree-form-edit-node--actions--header">
          <div className="d-flex align-items-center justify-content-between">
            <div />

            <Dropdown
              id="actions-select-type"
              pullRight
            >
              <Dropdown.Toggle
                style={{ backgroundColor: '#ffffff', border: '0px', boxShadow: 'unset' }}
                noCaret
              >
                <div className="mdi mdi-plus-box-outline" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {menuItems}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="workflow-tree-form-edit-node--actions--list">
          {children}
        </div>
      </Collapsible>
    );
  }
}

WorkflowTreeActions.propTypes = {
  form: PropTypes.object,
  onValueChange: PropTypes.func,
  validations: PropTypes.object
};

WorkflowTreeActions.defaultProps = {
  form: {},
  onValueChange: noop,
  validations: {}
};

export default WorkflowTreeActions;
