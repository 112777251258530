import _ from 'lodash';
import { createSelector } from 'reselect';
import { getEmailSubscriptionsItemsRaw, getEmailSubscriptionsSearchParams } from './emailSubscriptionsCommonSelectors';
import { getLanguage } from '../commonSelectors';
import moment from 'moment';

export const getEmailSubscriptionsItems = createSelector([
  getEmailSubscriptionsItemsRaw,
  getLanguage,
  getEmailSubscriptionsSearchParams,
], (items, locale, searchParams) => {
  function mapItem(item) {
    return {
      id: item.id,
      view: {
        email: item.email,
        name: [item.firstName, item.lastName].filter(Boolean).join(' '),
        subscriptionType: mapSubscriptionType(item.subscriptionType),
        subscriptionState: item.subscriptionState ? item.subscriptionState.text[locale] : '',
        createDate: item.createDate ? moment(item.createDate).format('L') : '-',
      },
      form: {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        title: item.title,
        gender: item.gender,
        language: item.language,
        subscriptionState: item.subscriptionState ? item.subscriptionState.id : null,
        subscriptionType: item.subscriptionType ? item.subscriptionType.map((it) => it.id) : [],
      },
    };
  }

  function mapSubscriptionType(subscriptionType) {
    if (!subscriptionType || !subscriptionType.length) {
      return '';
    }

    const types = subscriptionType.map((it) => it.text[locale]);
    if (searchParams.sortField === 'subscriptionType') {
      return _.orderBy(types, undefined, [searchParams.sortDirection]).join(', ');
    }

    return types.join(', ');
  }
  
  return items.map((item) => mapItem(item));
});
