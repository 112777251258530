import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const PropertyTree = ({ definition, onSelect }) => {
  const properties = _.get(definition, 'items.properties', {})

  const children = _.map(properties, (prop, key) => (
    <Branch
      key={key}
      name={key}
      path={key}
      property={prop}
      onSelect={onSelect}
    />
  ));

  return (
    <div>
      {children}
    </div>
  );
}

PropertyTree.propTypes = {
  definition: PropTypes.object,
  onSelect: PropTypes.func
};

PropertyTree.defaultProps = {
  definition: {},
  onSelect: _.noop
};




export class Branch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };

    this.toggle = this.toggle.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  toggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  onClick() {
    const { name, path, property, onSelect } = this.props;
    onSelect(path, name, property);
  }

  render() {
    const { expanded } = this.state;
    const { name, path, property, onSelect } = this.props;

    return (
      <div>
        <span>
          <span
            className={(property.properties) ? 'glyphicon glyphicon-plus' : 'glyphicon glyphicon-stop'}
            style={{ paddingRight: '10px' }}
            onClick={this.toggle} />
          <span onClick={this.onClick}>{name}</span>
        </span>
        {(expanded && property.properties) ? (
          _.map(property.properties, (prop, key) => (
            <div key={key} style={{ paddingLeft: '10px' }}>
              <Branch
                name={key}
                path={`${path}.${key}`}
                property={prop}
                onSelect={onSelect}
              />
            </div>
          ))
        ) : null}
      </div>
    );
  }
}

Branch.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  property: PropTypes.object.isRequired
};
